<template>
  <div :class="shade ? 'bg-shade':''" class="px-4 pt-10 pb-1 lg:pt-6">
    <div class="container mx-auto lg:pr-20">
      <div class="grid gap-4 mb-6 md:grid-cols-5 grid-row-auto">
        <div class="justify-center md:col-span-1 lg:my-4">
          <img v-if="c.ani" :src="imgsrc" style='max-width:190px' class="mx-auto mb-6 lg:mb-4 xl:mb-0"/>
        </div>
        <div class="flex items-center md:col-span-4">
          <div>
            <h2 class="mb-3 text-xl font-bold text-primary hover:text-black md:ml-16 lg:ml-4 xl:ml-0">
              <a href="#" @click.prevent="$parent.result(c)">
                <div>
                  {{c.Condition}}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="12"
                    height="12"
                    class="inline-block mb-1 ml-1"
                  >
                    <polygon fill="#f00" points="12,6 0,12 0,0" />
                  </svg>
                </div>
              </a>
            </h2>
            <p class="pr-10 mb-4 leading-tight md:ml-16 lg:ml-4 xl:ml-0" v-html="c.Description"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["shade", "c"],

  computed: {
    imgsrc() {
      return require("../assets/" + this.c.ani + ".gif");
    },
  }
};
</script>

<style>
</style>
