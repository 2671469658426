<template>
  <div class="flex items-center md:col-span-4">
    <div>
      <div class="text-lg font-bold text-primary hover:text-black">
        <a href="#" @click.prevent="$parent.result(condition)">
          <h2>
            {{condition.Condition}}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="12"
              height="12"
              class="inline-block mb-1 ml-1"
            >
              <polygon fill="#f00" points="12,6 0,12 0,0" />
            </svg>
          </h2>
        </a>
      </div>
      <p
        class="relative pr-10 mb-4 leading-tight cursor-pointer parent-arrow"
        @click.prevent="clamped=!clamped"
      >
       <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="10"
              height="8"
              style='float:right'
              class="inline-block mt-12 ml-1 mr-3 arrow-down"
              v-if="clamped"
            >
              <polygon fill="#A9A9A9" points="0,0 10,0 5,8" />
            </svg>
      <span :class="clamped ? 'clamp' : ''" v-html="condition.Description"></span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["condition"],
  data() {
    return {
      clamped: true,
    };
  },

};
</script>

<style>
.clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
