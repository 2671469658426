<template>
  <div class="flex flex-col">
    <div class>
      <div class="text-white bg-dark">
        <div class="container pt-5 pl-5 pr-4 mx-auto mb-20 lg:pr-20">
          <h1
            class="mt-6 mb-6 font-extrabold lg:mb-10 text-mhead lg:text-head lg:mt-16"
          >
            Overview
          </h1>
          <p class="font-medium">
            Splints, straps, and braces are used to stabilize the hand or wrist
            to promote the healing of musculoskeletal conditions caused by
            sports injuries, exercise, overextension during work, or simple
            everyday activities. Most injuries are soft tissue related,
            occurring in muscles, tendons, and ligaments.
          </p>
          <p class="my-6 text-lg font-bold">
            A splint, strap, or brace may offer relief for injuries that fall
            into two distinct categories:
          </p>
          <div class="lg:flex">
            <div class="flex items-start w-full">
              <div class="p-2 mr-4 rounded-full bg-light">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 335.94621 351.92844"
                  class="smallicon"
                  width="32"
                  height="32"
                >
                  <circle
                    cx="7107.7444"
                    cy="7063.20074"
                    r="38.44531"
                    transform="translate(-9885.68553 -1061.22602) rotate(-38.43868)"
                    fill="#fff"
                  />
                  <path
                    fill="#fff"
                    d="M7362.76679,7256.64236c-.25611-.20494-.54566-.3311-.80785-.52287l-79.55166-63.67836-.08005-.55833-75.68113,10.86254-23.75573-67.44605L7218.165,7083.42l.11805.12413,19.4705-28.28517-36.70187-38.7624c-.16491-.1867-.28169-.39874-.456-.57986a16.96126,16.96126,0,0,0-24.42831,23.53711c.175.18163.38353.30652.56441.47878l17.5997,18.47241-25.46593,37.45322-88.40306,31.13735.04813.1368-.05852-.03977-44.50527,65.45467.03952.02685-.89474,1.299,39.41928,48.78543c.152.19709.25459.41647.41672.60849a16.96117,16.96117,0,0,0,25.927-21.87505c-.16289-.193-.3625-.33135-.53173-.515l-23.52824-28.33787,17.624-25.92013,40.295,114.4035-.06688.26878,91.82345,22.82071,66.25239,53.03267c.2437.2133.42964.46713.68473.67131a22.0495,22.0495,0,0,0,27.55813-34.42778c-.25611-.20493-.54566-.33134-.80759-.52286l-79.55445-63.68039.03318-.1335-42.67474-10.60593,71.35611-10.24164,65.20566,52.19518c.2437.213.42989.46713.68474.671a22.04958,22.04958,0,0,0,27.55838-34.42777Z"
                    transform="translate(-7035.09164 -7010.72406)"
                  />
                  <path
                    fill="#fff"
                    d="M7104.85258,7353.94571a4.15455,4.15455,0,0,1-4.14236-4.39771l2.6062-43.49475-54.31475,4.15857a4.14972,4.14972,0,0,1-2.72374-7.51867l44.47563-31.65944a4.1501,4.1501,0,0,1,4.81519,6.76071l-32.31,23.00184,44.16962-3.38441a4.14983,4.14983,0,0,1,4.4585,4.38555l-2.26573,37.81624,33.73468-31.73241a4.151,4.151,0,0,1,6.40607.89576l15.12244,25.3081,6.44659-23.09506a4.15092,4.15092,0,0,1,7.99694,2.22925l-9.04671,32.41335a4.15015,4.15015,0,0,1-7.56122,1.01329l-17.43682-29.183-37.58723,35.356A4.15512,4.15512,0,0,1,7104.85258,7353.94571Z"
                    transform="translate(-7035.09164 -7010.72406)"
                  />
                </svg>
              </div>

              <p class="mt-1 font-medium">
                <span class="font-bold text-primary">Acute injuries</span>
                where swelling is common leads to tears, sprains or strains to
                soft tissue (eg, muscles, tendons, or ligaments). Examples
                include TFCC tears caused by a fall or injuries to joints in the
                hands from sports or other laborious activities
              </p>
            </div>

            <div class="flex items-start mt-6 lg:ml-8 lg:mt-0">
              <div class="p-2 mr-4 rounded-full bg-light">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 45 39"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="Website"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      transform="translate(-577.000000, -385.000000)"
                      fill="#FFFFFF"
                      fill-rule="nonzero"
                    >
                      <g
                        id="Overview-Section"
                        transform="translate(0.000000, -69.000000)"
                      >
                        <g
                          id="Overuse-Injuries"
                          transform="translate(570.000000, 443.000000)"
                        >
                          <g id="Group-2">
                            <g
                              id="noun_Reuse_6786"
                              transform="translate(7.000000, 11.000000)"
                            >
                              <g
                                id="Group-3"
                                transform="translate(0.000000, 0.197834)"
                              >
                                <path
                                  d="M22.4985204,31.2000501 C15.6336089,31.3115809 10.1502351,25.4498621 10.0416488,18.7269562 C10.0392984,18.5947193 10.043529,18.2827155 10.0458794,18.2827155 L13.5060693,18.2827155 L6.75303464,11.8021655 L7.31859018e-13,18.2827155 L3.32998033,18.2827155 C3.2472479,24.8710314 7.13990277,31.9031175 13.1356536,35.377513 C21.0408314,39.9587456 31.5859258,37.8391902 37.2704899,30.7798097 C37.4871924,30.5111005 37.6916732,30.2489796 37.8923933,29.9713292 L31.8975826,26.7797607 C29.637483,29.4386168 26.2910502,31.1388729 22.4985204,31.2000501 Z"
                                  id="Path"
                                />
                                <path
                                  d="M23.1764727,0.016517398 C16.9625939,-0.239501121 11.2261592,2.49707295 7.41892096,7.08457295 L13.2486657,10.4007767 C15.5582071,8.06281369 18.8065325,6.59383221 22.3016321,6.53873962 C26.1060284,6.47809147 29.4874508,8.18133221 31.7789933,10.8257767 C30.1202538,3.48410999 26.1391842,0.273924805 23.1764727,0.016517398 Z"
                                  id="Path"
                                />
                                <path
                                  d="M41.5514416,18.3206841 C41.5509679,18.3206841 41.5495469,18.35911 41.5485996,18.3526285 C41.2549336,10.6063322 36.2422436,3.67392481 28.9072236,1.05123962 C32.2322811,4.55448036 34.8572766,10.4503137 34.8572766,18.3206841 L34.8392777,18.3206841 L32.5553137,18.3206841 L31.363598,18.3206841 L38.1676508,24.697073 L44.9721774,18.3206841 L41.5514416,18.3206841 Z"
                                  id="Path"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>

              <p class="mt-1 font-medium">
                <span class="font-bold text-primary">Overuse injuries</span>
                and chronic pain caused by repetitive motions are repeated so
                often that the inflamed soft tissue has no time to recover in
                between occurrences. Common examples include tendinitis,
                bursitis, carpal tunnel syndrome, and arthritis of the wrist or
                thumb
              </p>
            </div>
          </div>
        </div>
        <!-- Call Out -->
        <callout />
      </div>

      <div class="w-full pt-0">
        <div class="mt-10 text-2xl font-bold text-center text-black lg:text-3xl">
          Common conditions:
        </div>
        <condition-line
          v-for="(condition, index) in common"
          :key="condition.id"
          :shade="index % 2 != 0"
          :c="condition"
        ></condition-line>
        <div class="py-4 bg-shade">
          <div class="ml-10">
            <div
              class="mt-4 mb-6 text-2xl font-bold text-center text-black lg:text-3xl"
            >
              Less common conditions:
            </div>
            <div class="container grid mx-auto md:grid-cols-3">
              <div v-for="condition in uncommon" :key="condition.id">
                <un-common :condition="condition"></un-common>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer-md class="px-10 text-gray-700 lg:px-0 lg:mt-0" />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import Callout from "../components/Callout.vue";
import ConditionLine from "../components/ConditionLine.vue";
import UnCommon from "../components/UnCommon.vue";
export default {
  name: "FindCondition",
  components: {
    "footer-md": Footer,
    callout: Callout,
    "condition-line": ConditionLine,
    "un-common": UnCommon,
  },
  computed: {
    common() {
      return this.$parent.cms.conditions.filter((i) => i.ani);
    },
    uncommon() {
      return this.$parent.cms.conditions.filter((i) => !i.ani);
    },
  },
  data() {
    return {
      clamped: [],
    };
  },
  created() {
    for (var i = 0; i < this.uncommon.length; i++) {
      this.clamped[i] = true;
    }
  },
  methods: {
    result(c) {
      if (c.Choice.trim() == "") {
        this.$parent.alert(
          "Evaluation of the best<br>splints for this condition<br>is being conducted.<br> Stay tuned!"
        );
      } else {
        window.router.push({
          name: "Results",
          params: {
            from: "condition",
            detail: this.$parent.slug(c.Condition),
          },
        });
      }
    },
  },
};
</script>
